<template>
  <span>{{ getValue }}</span>
</template>

<script>
import { get_value } from "@/helpers/utils";

export default {
  name: "GetValue",
  props: [
    "objeto",
    "chave",
    "valorPadrao"
  ],
  computed: {
    getValue: function () {
      return get_value(this.objeto, this.chave, this.valorPadrao);
    }
  }
}
</script>

<style scoped>

</style>