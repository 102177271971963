<template>
  <CBadge v-if="situacoes[situacao]" :color="situacoes[situacao].color" shape="pill">
    {{ situacoes[situacao].label }}
  </CBadge>
  <CBadge v-else color="secondary" shape="pill">
    {{ situacao }}
  </CBadge>
</template>

<script>

import situacoes from "@/config/situacoesOrcamento";

export default {
  name: "SituacaoOrcamento",
  props: [
    "situacao",
  ],
  data() {
    return {
      situacoes
    }
  }
}
</script>

<style scoped>

</style>