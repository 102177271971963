<template>
  <div class="row">
    <div class="col-6">
      <strong>{{ chave }}</strong>
    </div>
    <div class="col-6">
      {{ valor }}
      <slot/>
    </div>
  </div>
</template>

<script>

export default {
  name: "ChaveValor",
  props: [
    "chave",
    "valor",
    "valorPadrao",
  ],
}
</script>

<style scoped>

</style>