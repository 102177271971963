<template>
  <modal name="modalOrcamento" height="auto" width="900" :scrollable="true" :clickToClose="false">
    <CloseModal :close="closeModal"/>
    <div class="modalbox">
      <div class="modalbox-content" v-if="!!orcamento">
        <CTabs variant="tabs" class="nav-tabs-boxed">
          <CTab title="Cabeçalho">
            <CabecalhoOrcamento :orcamento="orcamento" />
          </CTab>
          <CTab title="Itens">
            <ItensOrcamento :orcamento="orcamento" />
          </CTab>
        </CTabs>
      </div>
    </div>
    <div class="modal-footer">      
      <button class="button" v-on:click="closeModal">Fechar</button>
    </div>
  </modal>
</template>

<script>
import CloseModal from "../../../components/CloseModal";
import ChaveValor from "@/components/base/ChaveValor.vue";
import Jsonblock from "@/components/form/ncform/Jsonblock.vue";
import GetValue from "@/components/base/GetValue.vue";
import SituacaoOrcamento from "@/components/base/SituacaoOrcamento.vue";
import {cdnUrlProduto} from "@/helpers/url";
import CabecalhoOrcamento from "@/views/Pedidos/OrcamentosPedidos/ModalOrcamento/CabecalhoOrcamento.vue";
import ItensOrcamento from "@/views/Pedidos/OrcamentosPedidos/ModalOrcamento/ItensOrcamento.vue";

export default {
  name: "modal-orcamento",
  props: ["close", "orcamento"],
  components: {
    ItensOrcamento,
    CabecalhoOrcamento,
    SituacaoOrcamento,
    GetValue,
    Jsonblock,
    ChaveValor,
    CloseModal
  },
  methods: {
    closeModal: function () {
      this.$modal.hide('modalOrcamento');
    },
    cdnUrl(id) {
      return cdnUrlProduto(id);
    },
  },
};
</script>
