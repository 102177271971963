<template>
  <div>
    <ChaveValor chave="ID" :valor="orcamento.id" />
    <ChaveValor chave="Situação">
      <SituacaoOrcamento :situacao="orcamento.situacao" />
    </ChaveValor>
    <ChaveValor chave="Data criação" :valor="orcamento.datahorainclusao | datetime" />
    <ChaveValor chave="Última atualização" :valor="orcamento.dataatualizacao | datetime" />
    <ChaveValor chave="Data integração" :valor="orcamento.datahoraintegracao | datetime" />
    <ChaveValor chave="Data entrega" :valor="orcamento.dataentrega | date" />
    <ChaveValor chave="Data validade" :valor="orcamento.datavalidade | date" />
    <ChaveValor chave="Sistema de origem" :valor="orcamento.plataforma_inicial" />
    <ChaveValor chave="Sistema de transmissão" :valor="orcamento.plataforma_final" />
    <ChaveValor chave="Operação" :valor="orcamento.operacao_id" />
    <ChaveValor chave="Cobrança" :valor="orcamento.cobranca_id" />
    <ChaveValor chave="Plano pagamento">
      {{ orcamento.planopagamento_id }}
      - <GetValue :objeto="orcamento" chave="planoPagamento.descricao" valor-padrao="N/D" />
    </ChaveValor>
  </div>
</template>

<script>

import ChaveValor from "@/components/base/ChaveValor.vue";
import GetValue from "@/components/base/GetValue.vue";
import SituacaoOrcamento from "@/components/base/SituacaoOrcamento.vue";

export default {
  name: "cabecalho-orcamento",
  props: ["orcamento"],
  components: {SituacaoOrcamento, GetValue, ChaveValor },
};
</script>
