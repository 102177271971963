<template>
  <div class="table-responsive">
    <table class="table table-hover">
      <thead>
        <tr>
          <th>#</th>
          <th>Cód.</th>
          <th>Seq.</th>
          <th>Produto</th>
          <th>Qt</th>
          <th>Vl.Unit</th>
          <th>Vl.Tot</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="orcamento.itens.length === 0">
          <td colspan="7" class="text-center">Nenhum item no orçamento/pedido</td>
        </tr>
        <template v-for="item in orcamento.itens" >
          <tr :key="item.produto_id" :class="{'modificado': item.motivo}">
            <td><img :src="cdnUrl(item.produto.imagem_id || item.produto_id)" class="thumbnail thumbnail-extra-small"/></td>
            <td>{{ item.produto_id }}</td>
            <td>{{ item.sequenciainsercao }}</td>
            <td>
              <GetValue :objeto="item.produto" chave="descricaonotafiscal" />
            </td>
            <td>{{ item.quantidade }}</td>
            <td>R$ {{ item.valorunitario }}</td>
            <td>R$ {{ item.valortotal }}</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>

import ChaveValor from "@/components/base/ChaveValor.vue";
import GetValue from "@/components/base/GetValue.vue";
import {cdnUrlProduto} from "@/helpers/url";

export default {
  name: "itens-orcamento",
  props: ["orcamento"],
  components: {GetValue, ChaveValor },
  methods: {
    cdnUrl(id) {
      return cdnUrlProduto(id);
    },
  }
};
</script>
