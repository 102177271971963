<template>
  <div>
    <ModalOrcamento
      :orcamento="orcamento"
    />
    <CTabs variant="tabs" class="nav-tabs-boxed">
      <CTab title="Orçamentos e pedidos">
        <crud-base
          :url="url"
          :columns="columns"
          :enableSearch="true"
          :formInputs="formInputs"
          :checkAlerts="false"
          :filters="filters"
          :showActions="true"
          primaryKey="id"
        >
          <!-- Filtros customizados -->
          <template v-slot:filter-search>
            <div class="row">
              <div class="col-sm-3">
                <div class="form-group ">
                  <label> <span>Situação</span> </label>
                  <select class="form-control" v-model="filters.situacao">
                    <option :value="null">TODOS</option>
                    <option v-for="(situacao, index) in situacoes" :key="index" :value="index">
                      {{ situacao.label }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group ">
                  <label> <span>Cód. Cliente</span> </label>
                  <input type="text" class="form-control" v-model="filters.cliente_id" />
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group ">
                  <label> <span>Cód. RCA</span> </label>
                  <input type="text" class="form-control" v-model="filters.representante_id" />
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group ">
                  <label> <span>Cód. Filial</span> </label>
                  <input type="text" class="form-control" v-model="filters.filial_id" />
                </div>
              </div>
            </div>
          </template>

          <!-- Apresentação customizada dos campos -->
          <template v-slot:cliente_id="{model}">
            <div><strong>{{ model.cliente_id }}</strong></div>
            <div><small>{{ model.cliente && model.cliente.razaosocial ? model.cliente.razaosocial : 'Não identificado' }}</small></div>
          </template>

          <template v-slot:datahorainclusao="{model}">
            <div><strong>{{ model.datahorainclusao | datetime }}</strong></div>
            <div><small>Alterado {{ model.dataatualizacao | datetime }}</small></div>
          </template>

          <template v-slot:colaborador="{model}">
            <div><strong>{{ model.colaborador_id }}</strong></div>
            <div><small>{{ model.colaborador && model.colaborador.nome ? model.colaborador.nome : 'Não identificado' }}</small></div>
          </template>

          <template v-slot:valortotal="{model}">
            <div><strong>{{ model.valortotal | monetary }}</strong></div>
            <div><small>{{ model.quantidadeitens }} iten(s)</small></div>
          </template>

          <template v-slot:situacao="{model}">
            <SituacaoOrcamento :situacao="model.situacao" />
          </template>

          <template v-slot:filial="{model}">
            <div><strong>{{ model.filial_id }}</strong></div>
            <div>
              <small>
                <GetValue :objeto="model.filial" chave="fantasia" />
                &nbsp;
                <GetValue :objeto="model.filial" chave="cidade" />
                &nbsp;
                <GetValue :objeto="model.filial" chave="uf" />
              </small>
            </div>
          </template>

          <!-- Ação customizada para abrir modal específica -->
          <template v-slot:action-buttons="{ model }">
            <CButton
              size="sm"
              color="info"
              title="Visualizar"
              @click="openModal(model)"
            >
              <fa-icon icon="search" />
            </CButton>
          </template>

        </crud-base>
      </CTab>
    </CTabs>
  </div>
</template>

<script>
import CrudBase from "../../../components/crud/CrudBase";
import ModalOrcamento from "@/views/Pedidos/OrcamentosPedidos/ModalOrcamento.vue";
import SituacaoOrcamento from "@/components/base/SituacaoOrcamento.vue";
import situacoes from "@/config/situacoesOrcamento";
import GetValue from "@/components/base/GetValue.vue";

export default {
  name: "crud-template",
  components: {
    GetValue,
    SituacaoOrcamento,
    ModalOrcamento,
    CrudBase,
  },
  data() {
    return {
      url: "/admin/orcamentos-e-pedidos",
      columns: [
        { label: "ID", field: "id", sortable: false },
        { label: "Cliente", field: "cliente_id", sortable: false, useSlot: true },
        { label: "Data inclusão", field: "datahorainclusao", sortable: false, useSlot: true },
        { label: "Criado", field: "colaborador", sortable: false, useSlot: true },
        { label: "Total", field: "valortotal", sortable: false, useSlot: true },
        { label: "Situação", field: "situacao", sortable: false, useSlot: true },
        { label: "Filial", field: "filial", sortable: false, useSlot: true },
      ],
      formInputs: null,
      filters: {
        situacao: null,
      },
      orcamento: null,
      situacoes: situacoes
    };
  },
  methods: {
    openModal(model) {
      this.orcamento = model;
      this.$modal.show('modalOrcamento');
    }
  }
};
</script>
