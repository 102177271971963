const situacoes = {
    CARRINHO: { color: 'info', label: 'CARRINHO' },
    ORCAMENTO: { color: 'primary', label: 'ORÇAMENTO' },
    ORCAMENTO_ERP: { color: 'primary', label: 'ORÇAMENTO (ERP)' },
    PEDIDO: { color: 'success', label: 'PEDIDO' },
    PRE_PEDIDO: { color: 'info', label: 'PRÉ-PEDIDO' },
    REPRESENTANTE: { color: 'primary', label: 'COM REPRESENTANTE' },
    CALLCENTER: { color: 'primary', label: 'COM CALLCENTER' },
    EXCLUIDO: { color: 'danger', label: 'EXCLUÍDO' },
    PERDIDO: { color: 'danger', label: 'PERDIDO' },
    CANCELADO_ERP: { color: 'danger', label: 'CANCELADO' },
    PEDIDO_MESCLADO: { color: 'danger', label: 'MESCLADO' },
}

export default situacoes;